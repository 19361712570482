import { getStaticData } from './getStaticData';
import { Container, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import './static-pages.scss';

const GetView = ({ tag, value }) => {
  if (tag == 'h1') {
    return (
      <>
        <h1 className="section-heading">{value}</h1>
        <Button
          variant="contained"
          color="primary"
          href="/"
          style={{
            float: 'right',
            color: '#fff',
            backgroundColor: '#2598fb'
          }}
        >
          Check Vehicle History
        </Button>
      </>
    );
  }
  return <h2 className="section-heading">{value}</h2>;
};
const HistoryReport = () => {
  const { pathname: currentPath } = useLocation();
  const allData = getStaticData(currentPath);
  return (
    <>
      <div id="car-history-report" className="static-page-wrapper">
        <Container className="container">
          {allData.map((data, index) => (
            <section className="section-wrapper" key={index}>
              <GetView tag={data.tag} value={data.heading} />
              <p dangerouslySetInnerHTML={{ __html: data.content }} />
            </section>
          ))}
        </Container>
      </div>
    </>
  );
};

export default HistoryReport;
