import axios from 'axios';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  paymentMethodWrapper: {},
}));

export default function PaymentMethod() {
  const classes = useStyles();
  const { payMethod } = useSelector(state => state.basicDetail); 
  const [paymentOptions, setPaymentOptions] = useState([]); 

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      const response = await axios.get('drapi/payment-options');
      const paymentData = response?.data?.data?.data || [];
      if (Array.isArray(paymentData)) {
        setPaymentOptions(paymentData);
      }
    };
    fetchPaymentOptions();
  }, []);

  return (
    <div className="detail-block">
      <CheckCircleIcon className="green-check-icon" />
      {paymentOptions
        .filter(item => item.value === payMethod) 
        .map(filteredItem => (
          <div className="detail-panel" key={filteredItem.value}>
            {filteredItem.name} 
          </div>
        ))}
    </div>
  );
}
