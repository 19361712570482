import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import './static-pages.scss';

const blogList = [
  {
    link: '/blog/check-vehicle-details-online-on-history',
    heading: 'Check Vehicle Details Online on history',
    content:
      'History has the largest vehicle details online database in India with information on over twenty two crore vehicles across the country. The website contains vehicle history data and other related information on its database, making it the biggest open source platform for verified vehicle owners and Vehicle Details. You can get up to 50 different history records and vehicle details within ten seconds. ...'
  },
  {
    link: '/blog/how-to-get-vahan-information-online',
    heading: 'How to get Vahan Information Online?',
    content:
      'Gone are the days when we had to visit regional transport offices (RTOs) and district transport offices (DTOs) to get vehicle owner details or vehicle registration details. Unfortunately, hit and run cases are more than usual. Even if you manage to capture the vehicle’s registration number, you might not be able to find its details easily....'
  },
  {
    link: '/blog/how-to-find-vehicle-owner-contact-details-by-registration-number',
    heading:
      'How to Find Vehicle Owner Contact Details by Registration Number?',
    content:
      'India has one of the fastest-growing automobile markets in the world. As of 2020, they are the fifth-largest automobile market in the world in terms of new car registrations. Every car bought needs to be registered by its owner. This is a mandatory process. ...'
  },
  {
    link: '/blog/how-to-find-vehicle-owner-contact-details-by-registration-number',
    heading:
      'How to Find Vehicle Owner Contact Details by Registration Number?',
    content:
      'India has one of the fastest-growing automobile markets in the world. As of 2020, they are the fifth-largest automobile market in the world in terms of new car registrations. Every car bought needs to be registered by its owner. This is a mandatory process. ...'
  },
  {
    link: '/blog/how-to-check-vehicle-registration-details',
    heading: 'How to Check Vehicle Registration Details?',
    content:
      'Purchasing a used vehicle in India requires careful assessment to enable a hassle-free and safe transaction. Confirmation of the registration details of the vehicle is probably the most important aspect of the process. It confirms ownership, verifies details like model, chassis number, insurance, and searches for any outstanding legal or financial issues. The majority of fraudsters sell vehicles that are stolen ....'
  }
];
const Blogs = () => {
  return (
    <div id="history-blogs" className="static-page-wrapper">
      <Container className="container">
        <section className="section-wrapper">
          <h1 className="section-heading">Blog</h1>
          <Button
            variant="contained"
            color="primary"
            href="/"
            style={{
              float: 'right',
              color: '#fff',
              backgroundColor: '#2598fb'
            }}
          >
            Check Car History
          </Button>
        </section>
        {blogList &&
          blogList.map((blog, index) => (
            <section className="section-wrapper" key={index}>
              <h2 className="sub-heading">
                <Link to={blog.link}>{blog.heading}</Link>
              </h2>
              <p>{blog.content}</p>
              <Link to={blog.link}>Continue Reading</Link>
            </section>
          ))}
      </Container>
    </div>
  );
};
export default Blogs;
