import Home from '@pages/Home';
import NotFound from '@pages/NotFound';
import LoginJourney from '@loginJourney/Index';
import ThankYou from '@loginJourney/components/thank-you/ThankYou';
import MyAccount from '@loginJourney/components/my-account/MyAccount';
import WhatHistory from '@pages/StaticFile/WhatHistory';
import FaqsHistory from '@pages/StaticFile/FaqsHistory';
import PrivacyPolicy from '@pages/StaticFile/PrivacyPolicy';
import Parameters from '@pages/StaticFile/Parameters';
import TermsCondition from '@pages/StaticFile/TermsCondition';
import Methodology from '@pages/StaticFile/Methodology';
import Glossary from '@pages/StaticFile/Glossary';
import Blogs from '@pages/StaticFile/Blogs';
import InThePress from '@pages/StaticFile/InThePress';
import CheckVehicleDetails from '@pages/StaticFile/blogs/CheckVehicleDetails';
import VehicleOwnerDetails from '@pages/StaticFile/blogs/VehicleOwnerDetails';
import VahanInformationOnline from '@pages/StaticFile/blogs/VahanInformationOnline';
import PaymentFailure from '@loginJourney/components/failure/PaymentFailure';
import HistoryReport from '@pages/StaticFile/HistoryReport';
import CheckVehicleRegistration from '@pages/StaticFile/blogs/CheckVehicleRegistration';
const routes = [
  {
    path: '/',
    exact: true,
    menu: 'Home',
    component: Home,
    isPublic: true
  },
  {
    path: '/checkout',
    exact: true,
    component: LoginJourney
  },
  {
    path: '/payment-option',
    exact: true,
    component: LoginJourney
  },
  {
    path: '/order/summary',
    exact: true,
    component: ThankYou
  },
  {
    path: '/payment/failure',
    exact: true,
    component: PaymentFailure
  },
  {
    path: '/account',
    exact: true,
    component: MyAccount
  },
  {
    path: '/what-history',
    exact: true,
    component: WhatHistory,
    isPublic: true
  },
  {
    path: '/car-history-report',
    exact: true,
    component: HistoryReport,
    isPublic: true
  },
  {
    path: '/faqs',
    exact: true,
    component: FaqsHistory,
    isPublic: true
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    isPublic: true
  },
  {
    path: '/parameters',
    exact: true,
    component: Parameters,
    isPublic: true
  },
  {
    path: '/terms-of-use',
    exact: true,
    component: TermsCondition,
    isPublic: true
  },
  {
    path: '/bike-history-report',
    exact: true,
    component: HistoryReport,
    isPublic: true
  },
  {
    path: '/scooter-history-report',
    exact: true,
    component: HistoryReport,
    isPublic: true
  },
  {
    path: '/methodology',
    exact: true,
    component: Methodology,
    isPublic: true
  },
  {
    path: '/glossary',
    exact: true,
    component: Glossary,
    isPublic: true
  },
  {
    path: '/blog',
    exact: true,
    component: Blogs,
    isPublic: true
  },
  {
    path: '/blog/check-vehicle-details-online-on-history',
    exact: true,
    component: CheckVehicleDetails,
    isPublic: true
  },
  {
    path: '/blog/how-to-get-vahan-information-online',
    exact: true,
    component: VahanInformationOnline,
    isPublic: true
  },
  {
    path: '/blog/how-to-find-vehicle-owner-contact-details-by-registration-number',
    exact: true,
    component: VehicleOwnerDetails,
    isPublic: true
  },
  {
    path: '/blog/how-to-check-vehicle-registration-details',
    exact: true,
    component: CheckVehicleRegistration,
    isPublic: true
  },
  {
    path: '/in-the-press',
    exact: true,
    component: InThePress,
    isPublic: true
  },
  {
    component: NotFound,
    isPublic: true
  }
];

export default routes;
