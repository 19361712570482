export const getStaticData = pathname => {
  if (pathname == '/car-history-report') return carContent;
  if (pathname == '/bike-history-report') return bikeContent;
  if (pathname == '/scooter-history-report') return scooterContent;
  return [];
};

const carContent = [
  {
    tag: 'h1',
    heading: 'Check Car History',
    content:
      "A used car has to be bought carefully. It might look shiny and drive well, but what if it has pending loans, major accidents, or was reported stolen? Without knowing the car’s past, you could end up with unexpected troubles.\n\nThat's where a Car History Report can help you in buying a used car sensibly. With just the car’s registration number, you can instantly access details like ownership records, accident reports, theft history, insurance claims, RTO car info, and more."
  },
  {
    heading: 'What are the Benefits of Checking Car History?',
    content:
      "If you're buying a used car, how can you be sure it's trouble-free? You may find a great deal on a used car, but it could have hidden issues like previous accidents, pending loans, or theft records. Checking the car history is the most important step that one should take before making the final purchase decision.\n\nA simple online car history check can save you from this costly buying mistake. You can instantly access car owner details, accident history, insurance claims, and more online right from your phone without any paperwork and hassle. It’s that simple!"
  },
  {
    heading: 'How to Check Car History Online?',
    content:
      'You can check the history of the car online just by providing the car registration number on the online platform. A car history report will be shared afterward, which will include information regarding car registration details, theft and accidental history, car owner details, flood damage history, loan history, and other important information.'
  },
  {
    heading: 'How to Check Car Owner Details?',
    content:
      'Anyone can obtain car owner details either online or offline. Offline methods include going to the RTO in your city and asking for the required car information. Online methods include the procedure of submitting the valid registration number of the car on the online platform to get information regarding car owner details along with accident claim history, insurance history, car RTO details, flood damage history, loan history, theft history, and service log history.'
  },
  {
    heading: 'How to Get Car RTO Information?',
    content:
      'RTO (Regional Transport Office) maintains essential records about cars registered within their jurisdiction. You can check car RTO information by entering the car’s registration number in an online portal. This will give you access to details such as owner name, registration validity, fuel type, and chassis number.'
  },
  {
    heading: 'How to Get Car Information Online?',
    content:
      'A trustworthy car history platform will be your ultimate solution if you are seeking car information online. Entering the car’s registration number in the platform provides car information such as accident records, insurance status, and service history.'
  },
  {
    heading: 'How to Check Car Registration Details?',
    content:
      'It’s quite easy! Visit a reliable online portal and enter the car’s registration number to check registration details. Crucial information such as the registered owner’s name, registration date, and fitness certificate status will be provided against the car registration number, which is essential to confirm that you’re investing in a legally registered car.'
  },
  {
    heading: 'Why Should You Choose Droom History?',
    content:
      'There are ample benefits to choosing Droom <a href="/"> Vehicle History </a> over any other platform. The Droom History report shares essential car information online, such as car loan history, car fitness report validity, car RTO details, used car real age, blacklist history (if any), etc. All these details help you to have clarity over the used car you are planning to purchase and make an informed purchase decision.'
  }
];

const bikeContent = [
  {
    tag: 'h1',
    heading: 'Check Bike History',
    content:
      'Every used bike has a story, and knowing its past can save you from a good number of future troubles. A bike history report reveals crucial details such as ownership records, accident history, insurance claims, and service updates. This bike information helps buyers make informed purchase decisions. The bike history check protects you from fraud, hidden damage, and legal issues, making it a must-do step before buying a second-hand bike.'
  },
  {
    heading: 'What are the Benefits of Checking Bike History?',
    content:
      'Surprise is the last thing one wants when buying a used bike! A detailed bike history check helps uncover hidden issues, ensuring you don’t end up with a stolen or accident-prone bike. It verifies legal ownership, insurance validity, and past maintenance records, which builds confidence to buy a used bike and helps avoid fraud. It is also a great help to sellers, as they can provide credibility to potential buyers.'
  },
  {
    heading: 'How to Check Bike History Online?',
    content:
      'To be completely sure about the condition of any used bike before finalizing it for purchase, one needs to go through a proper background check. This is where the role of bike history comes in. As an online tool for bike history checks, it gives thorough information about the background of any used bike through a history report, which includes information regarding bike registration details, theft and accidental history, bike owner details, flood damage history, loan history, etc. You can easily get the report by providing two-wheeler registration details, i.e., the registration number of any pre-owned bike.'
  },
  {
    heading: 'How to Check Bike Owner Details?',
    content:
      'Each owner of the vehicle is bound to register with the RTO or Regional Transport Office, where they keep a record of all the two-wheelers like bike, scooter. For example, if you have a bike, you must register the bike details and owner details, either manually or through online bike registration. These details can be easily tracked by anyone before purchasing the used bike. For bike history checks, you need to submit the valid registration number of the bike, and bike owner details along with accident claim history, insurance history, bike RTO details, flood damage history, loan history, theft history, and service logs history will be provided to you within 10 seconds.'
  },
  {
    heading: 'How to Check RTO Information?',
    content:
      'Verifying a bike’s registration details is essential before making a purchase. Enter the bike’s registration number in a trusted online portal to check RTO information. Details such as owner name, registration validity, fuel type, and chassis number will be revealed. Ensuring these details match the seller’s claims helps avoid legal disputes and ensures a hassle-free transaction. It’s always a safer option to double-check!'
  },
  {
    heading: 'How to Check Bike Information Online?',
    content:
      'Now one can check bike information online simply by using a reliable bike history platform by entering the bike’s registration number, giving them access to essential details such as accident records, insurance status, and service history. This quick and easy method helps you avoid scams, verify authenticity, and make an informed purchase decision, all these from the comfort of your home!'
  },
  {
    heading: 'How to Check Registration Details?',
    content:
      'Before buying a used bike, verifying its registration is a smart move. To check registration details, enter the bike’s registration number on a trusted online portal. Key information such as the registered owner’s name, registration date, and fitness certificate status will be provided. Cross-checking these details with the official records confirms that you’re buying a legally registered bike with no hidden surprises.'
  },
  {
    heading: 'Why Choose Droom History?',
    content:
      'Thorough research on a used bike is needed before buying it because you never know what is hidden under the shining outer appearance of the second-hand bike you are going to purchase. With the help of the Droom <a href="/"> Vehicle History </a> premium report, you will get all the necessary used bike information. This information will further assist you in making a proper decision while buying a second-hand bike, as it will provide you with all the bike information online, including bike loan history, bike fitness report validity, bike RTO details, the real age of the used bike, blacklist history (if any), etc. Wait no more and choose Droom History.'
  }
];

const scooterContent = [
  {
    tag: 'h1',
    heading: 'Check Scooter History',
    content:
      'Buying a used scooter can be a risky decision without knowing its past. Has it been in an accident? Does it have pending loans? Was it ever stolen? Not knowing this information you could end up with a problematic scooter, unnecessary costs, and legal hassles.\n\nThat’s where the Scooter History Report comes in. With a few taps on your smartphone or visiting the online portal, you can check reports on ownership, accidents, thefts, and insurance claims.'
  },
  {
    heading: 'What are the Benefits of Checking Scooter History?',
    content:
      "When buying a used scooter it may look good but what if it was in a major accident or has unpaid loans attached to it? You may find a great deal on a used scooter. The seller assures you it’s in perfect condition. Suppose after buying it, you discover it was reported stolen, and now you're stuck in a legal mess. A simple online scooter history check could have saved you from this hassle. With just the scooter registration number, you can easily check the scooter's history from your phone. There's no need for tedious paperwork or trips to RTO offices."
  },
  {
    heading: 'How to Check Scooter History Online?',
    content:
      'A buyer can check scooter history report online in India through an online portal which offers key details about scooter registration and scooty owner details. Few portals provide scooter information in a comprehensive report which usually lists past accidents, ownership transfers, theft incidents, odometer readings, and flood damage.'
  },
  {
    heading: 'How to Check Scooter Owner Details?',
    content:
      "Obtaining scooter owner details is the most crucial task that one should not skip. Online portals are where buyers can check scooter owner details by entering the registration number of the scooter. Information such as the owner's name, address, and registration validity are revealed through these portals. Only the authorized platform should be used for accurate data and protecting privacy."
  },
  {
    heading: 'How to Check Scooter RTO Information?',
    content:
      'RTO (Regional Transport Office) keeps important records regarding vehicles registered within their area. One can check RTO information through online portals or mobile apps. They just have to enter the registration number of the scooter and information related to registration status, owner name, insurance validity, and road tax information will be presented to them.'
  },
  {
    heading: 'How to Check Scooter Information Online?',
    content:
      'If you want scooter information online which includes make, model, fuel type, registration status, and insurance validity of a scooter, online portals will be of great help to you. These details can be easily fetched just by entering the registration number of the scooter and then you are good to go.'
  },
  {
    heading: 'How to Check Scooter Registration Details?',
    content:
      "The validity of a scooter’s registration is of great help, keeping buyers away from all the legal troubles. In order to check scooter registration details online, one should visit an online vehicle history portal and enter the scooter registration number which will give them access to details such as ownership details, registration date, fuel type, and tax status. To get offline scooter registration information, one can go to the RTO (Regional Transport Office) in their locality and ask for the scooter's registration details."
  },
  {
    heading: 'Why Choose Droom History?',
    content:
      'You can access scooter information online regarding its background through Droom <a href="/"> Vehicle History </a> which provides unbiased and accurate comprehensive reports. The scooter information which is included in the report are loan history, scooter owner details, scooty fitness report validity, scooter registration details, scooter age, blacklisted scooters, the number of times the scooter is resold, scooter accident history, etc. This information can be easily fetched by entering the registration number of the scooter.'
  }
];
